import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useSafePromise } from "../helpers/use-safe-promise";
import { FastboardModule, fastboardPromise } from "../behaviors/fastboard";
import { useTheme } from "../helpers/use-theme";

export function Replay() {
  const uuid = useParams<"uuid">().uuid;
  const [query] = useSearchParams();
  const roomToken = query.get("token");
  const region = query.get("region");
  const beginTimestamp = query.get("beginTimestamp");
  const duration = query.get("duration");
  const [fastboard, setFastboard] = useState<FastboardModule | null>(null);
  const sp = useSafePromise();

  useEffect(() => {
    if (!fastboard) {
      sp(fastboardPromise).then(setFastboard);
    }
  }, []);

  const messages = validate(uuid, roomToken, region, beginTimestamp, duration);
  if (messages.length) {
    return (
      <ul className="room-error">
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className="whiteboard">
      {fastboard && uuid && roomToken && region && beginTimestamp && duration && (
        <ReplayImpl
          fastboard={fastboard}
          uuid={uuid}
          region={region}
          token={roomToken}
          beginTimestamp={+beginTimestamp}
          duration={+duration}
        />
      )}
    </div>
  );
}

function validate(
  uuid: string | undefined,
  roomToken: string | null,
  region: string | null,
  beginTimestamp: string | null,
  duration: string | null
) {
  const messages: string[] = [];
  if (!uuid) messages.push("Missing room uuid.");
  if (!roomToken) messages.push("Missing roomToken.");
  if (!region) messages.push("Missing region.");
  if (!beginTimestamp) messages.push("Missing beginTimestamp.");
  if (!duration) messages.push("Missing duration.");
  return messages;
}

interface ReplayProps {
  fastboard: FastboardModule;
  uuid: string;
  region: string;
  token: string;
  beginTimestamp: number;
  duration: number;
}

function ReplayImpl({ fastboard, uuid, region, token, beginTimestamp, duration }: ReplayProps) {
  const [theme] = useTheme();
  const { useReplayFastboard, ReplayFastboard } = fastboard;

  const player = useReplayFastboard(() => ({
    sdkConfig: {
      appIdentifier: import.meta.env.VITE_APPIDENTIFIER,
      region,
    },
    replayRoom: {
      room: uuid,
      roomToken: token,
      beginTimestamp,
      duration,
    },
    managerConfig: {
      debug: true,
    },
  }));

  return <ReplayFastboard player={player} theme={theme} />;
}
