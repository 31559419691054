import Countdown from "@netless/app-countdown";
import GeoGebra from "@netless/app-geogebra";
import EmbeddedPage, { type AppOptions as EmbeddedPageOptions } from "@netless/app-embedded-page";
export type FastboardModule = typeof import("@netless/fastboard-react");

let fastboard: FastboardModule | undefined;

export async function importFastboard(): Promise<FastboardModule> {
  if (!fastboard) {
    fastboard = await import("@netless/fastboard-react");
    initialize(fastboard);
  }
  return fastboard;
}

export const fastboardPromise = importFastboard();

fastboardPromise.then((Fastboard) => Object.assign(window, { Fastboard }));

function initialize(fastboard: FastboardModule) {
  // Add extra buttons to the last panel in toolbar.
  const { apps, register } = fastboard;

  // register newer Monaco Editor
  register({
    kind: "Monaco",
    src: "https://netless-app.oss-cn-hangzhou.aliyuncs.com/@netless/app-monaco/0.1.14-beta.1/dist/main.iife.js",
  });

  // register newer GeoGebra
  register({
    kind: "GeoGebra",
    src: GeoGebra,
  });
  const geogebra_def = apps.data.find((e) => e.kind === "GeoGebra");
  if (geogebra_def) {
    geogebra_def.onClick = function (app) {
      app.manager.addApp({
        kind: "GeoGebra",
        options: { title: "GeoGebra" },
        attributes: { appName: "suite" },
      });
    };
  }

  // register newer Countdown
  register({
    kind: "Countdown",
    src: Countdown,
  });

  // register newer EmbeddedPage
  register({
    kind: "EmbeddedPage",
    src: EmbeddedPage,
    appOptions: {
      setupIframe(iframe) {
        iframe.setAttribute(
          "sandbox",
          // all but allow-top-navigation
          [
            "allow-downloads",
            "allow-forms",
            "allow-modals",
            "allow-orientation-lock",
            "allow-pointer-lock",
            "allow-popups",
            "allow-popups-to-escape-sandbox",
            "allow-presentation",
            "allow-same-origin",
            "allow-scripts",
          ].join(" "),
        );
      },
    } satisfies EmbeddedPageOptions,
  });

  // DocsViewer (static mode)
  // Note: use our slide conversion service to obtain the task id
  // https://developer.netless.link/server-en/home/server-conversion
  apps.push({
    icon: "https://api.iconify.design/mdi:file-word-box.svg?color=%237f7f7f",
    kind: "DocsViewer",
    label: "Docs",
    onClick: (app) => {
      app.insertDocs({
        fileType: "pdf",
        scenePath: `/pdf/18140800fe8a11eb8cb787b1c376634e`,
        title: "a.pdf",
        scenes: [
          {
            name: "1",
            ppt: {
              height: 1010,
              src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/1.png",
              width: 714,
            },
          },
          {
            name: "2",
            ppt: {
              height: 1010,
              src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/2.png",
              width: 714,
            },
          },
        ],
      });
    },
  });

  // Slide
  // Note: use our slide conversion service to obtain the task id
  // https://developer.netless.link/server-en/home/server-conversion
  apps.push({
    icon: "https://api.iconify.design/mdi:file-powerpoint-box.svg?color=%237f7f7f",
    kind: "Slide",
    label: "Slide",
    onClick: (app) => {
      let taskId: string;
      let url: string | undefined;
      if (app.room.region === "cn-hz") {
        taskId = "82d16c40b15745f0b5fad096ac721773";
      } else {
        taskId = "1bd92aa00e28413c8668cffdbc97116f";
        url = "https://convertcdn-sg.netless.link/dynamicConvert";
      }
      app.insertDocs({
        fileType: "pptx",
        scenePath: `/pptx/${taskId}`,
        taskId,
        title: "a.pptx",
        url,
      });
    },
  });

  let imageIndex = 0;

  // Image
  // Note: you have to implement file uploading and hosting by yourself
  apps.push({
    icon: "https://api.iconify.design/ic:baseline-image.svg?color=%237f7f7f",
    kind: "Image",
    label: "Image",
    onClick: (app) => {
      app.insertImage(`https://flat-storage-sg.oss-accelerate.aliyuncs.com/fastboard/${imageIndex}.webp`);
      imageIndex = (imageIndex + 1) % 3;
    },
  });

  // Media
  // Note: you have to implement file uploading and hosting by yourself
  apps.push({
    icon: "https://api.iconify.design/mdi:filmstrip-box.svg?color=%237f7f7f",
    kind: "Media",
    label: "Media",
    onClick: (app) => {
      app.insertMedia("a.mp4", "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4");
    },
  });

  // YouTube Video
  apps.push({
    icon: "https://api.iconify.design/logos:youtube-icon.svg?color=%237f7f7f",
    kind: "Plyr",
    label: "YouTube",
    onClick(app) {
      const url = window.prompt("Enter YouTube URL", "https://www.youtube.com/watch?v=bTqVqk7FSmY");
      if (!url) return;
      app.manager.addApp({
        kind: "Plyr",
        options: { title: "YouTube" },
        attributes: {
          src: url,
          provider: "youtube",
        },
      });
    },
  });

  // Google Docs
  apps.push({
    icon: "https://api.iconify.design/logos:google-icon.svg?color=%237f7f7f",
    kind: "EmbeddedPage",
    label: "Google Docs",
    onClick(app) {
      const url = window.prompt(
        "Enter Google Doc Share URL",
        "https://docs.google.com/document/d/1bd4SRb5BmTUjPGrFxU2V7KI2g_mQ-HQUBxKTxsEn5e4/edit?usp=sharing",
      );
      if (!url) return;
      app.manager.addApp({
        kind: "EmbeddedPage",
        options: { title: "Google Docs" },
        attributes: {
          src: url,
        },
      });
    },
  });

  register({
    kind: "PDFjs",
    src: "https://cdn.jsdelivr.net/npm/@netless/app-pdfjs@0.1.1",
    name: "NetlessAppPDFjs",
  });

  apps.push({
    icon: "https://api.iconify.design/mdi:file-pdf.svg?color=%237f7f7f",
    kind: "PDFjs",
    label: "PDF.js",
    async onClick(app) {
      const url = window.prompt(
        "Enter PDF File URL",
        "https://cdn.jsdelivr.net/gh/mfogel/polygon-clipping/paper.pdf",
      );
      if (!url) return;
      const i = url.lastIndexOf("/");
      const title = i >= 0 ? url.slice(i + 1) : url;
      app.manager.addApp({
        kind: "PDFjs",
        options: {
          title,
          scenePath: `/pdfjs/${Math.random().toString(36).slice(2)}`,
        },
        attributes: {
          src: url,
        },
      });
    },
  });

  // Save snapshot (since white-web-sdk 2.16.36)
  apps.push({
    icon: "https://api.iconify.design/mingcute:screenshot-line.svg?color=%237f7f7f",
    kind: "Snapshot",
    label: "Screenshot",
    onClick(app) {
      const view = app.manager.mainView;
      const canvas = document.createElement("canvas");
      canvas.width = view.size.width;
      canvas.height = view.size.height;
      const c = canvas.getContext("2d");
      if (!c) {
        alert("canvas.getContext('2d') failed!");
        return;
      }
      view.screenshotToCanvas(
        c,
        view.focusScenePath || "/init",
        view.size.width,
        view.size.height,
        view.camera,
      );
      try {
        canvas.toBlob((blob) => {
          if (!blob) {
            alert("context.toBlob() failed!");
            return;
          }
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "screenshot.png";
          a.click();
        });
      } catch (err) {
        const dialog = document.createElement("section");
        dialog.className = "dialog";
        const header = document.createElement("p");
        header.textContent =
          "This image contains CORS resources that cannot be exported to URL, you can right click and save this image manually.";
        const closeBtn = document.createElement("button");
        closeBtn.innerHTML = "&cross;";
        closeBtn.title = "close dialog";
        closeBtn.onclick = function closeDialog() {
          if (dialog.parentElement) dialog.remove();
        };
        header.appendChild(closeBtn);
        dialog.appendChild(header);
        dialog.appendChild(canvas);
        document.body.appendChild(dialog);
      }
    },
  });

  // Save PDF (need jspdf)
  apps.push({
    icon: "https://api.iconify.design/material-symbols:download-rounded.svg?color=%237f7f7f",
    kind: "SavePDF",
    label: "Save PDF",
    onClick(app) {
      const slides = app.manager
        .queryAll()
        .filter((app) => app.kind === "Slide" || app.kind === "DocsViewer");
      if (slides.length === 0) {
        alert("No slide found, please add a slide first.");
        return;
      }
      const dialog = document.createElement("section");
      dialog.className = "dialog";
      const closeBtn = document.createElement("button");
      closeBtn.innerHTML = "&cross;";
      closeBtn.title = "close dialog";
      closeBtn.onclick = function closeDialog() {
        if (dialog.parentElement) dialog.remove();
      };
      const info = document.createElement("p");
      info.textContent = `Found ${slides.length} ${
        slides.length > 1 ? "slides" : "slide"
      }, please select the slide you want to export.`;
      info.appendChild(closeBtn);
      dialog.appendChild(info);
      const section = document.createElement("div");
      const select = document.createElement("select");
      slides.forEach((slide) => {
        const option = document.createElement("option");
        option.value = slide.id;
        option.textContent = (slide.box?.title || "Untitled") + " (" + slide.id + ")";
        select.appendChild(option);
      });
      section.appendChild(select);
      section.appendChild(document.createTextNode(" "));
      const btn = document.createElement("button");
      btn.textContent = "Start";
      section.appendChild(btn);
      section.appendChild(document.createElement("br"));
      const progress = document.createElement("progress");
      progress.max = 100;
      progress.value = 0;
      section.appendChild(progress);
      const status = document.createElement("span");
      section.appendChild(document.createTextNode(" "));
      section.appendChild(status);
      dialog.appendChild(section);
      const link = document.createElement("a");
      link.href = "https://github.com/netless-io/window-manager/blob/master/docs/export-pdf.md";
      link.target = "_blank";
      link.textContent = "API Doc";
      dialog.appendChild(link);
      btn.onclick = function savePDF() {
        btn.disabled = true;
        status.innerText = "0%";
        postMessage({
          type: "@netless/_request_save_pdf_",
          appId: select.value,
        });
        const handler = (ev: MessageEvent) => {
          if (ev.data.type === "@netless/_result_save_pdf_") {
            const value = (progress.value = ev.data.progress);
            status.innerText = value + "%";
            if (value === 100) {
              if (ev.data.result) {
                console.log(ev.data.result);
                const a = document.createElement("a");
                const buffer = ev.data.result.pdf as ArrayBuffer;
                a.href = URL.createObjectURL(new Blob([buffer], { type: "application/pdf" }));
                a.title = ev.data.result.title;
                a.download = "download.pdf";
                a.innerText = "Download";
                link.before(a);
              } else {
                status.innerText = "Failed";
              }
            }
          }
        };
        window.addEventListener("message", handler);
      };
      document.body.appendChild(dialog);
    },
  });

  // Export scene
  apps.push({
    icon: "https://api.iconify.design/mdi:file-export-outline.svg?color=%237f7f7f",
    kind: "Export",
    label: "Export",
    async onClick(app) {
      const { scenePath } = app.manager.sceneState;
      console.log("export scene", scenePath);
      const blob = await app.room.exportScene(scenePath);
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.title = scenePath;
      a.download = `whiteboard${scenePath.replace(/\//g, "-")}.scene`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      setTimeout(() => {
        a.remove();
        URL.revokeObjectURL(url);
        alert("Drop the 'whietboard-*.scene' file to import it.");
      }, 1000);
    },
  });
}
