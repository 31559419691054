import React, { PropsWithChildren } from "react";

export function Loading({ children }: PropsWithChildren) {
  return (
    <div className="page-loading">
      <div className="la-square-jelly-box">
        <div />
        <div />
      </div>
      {children}
    </div>
  );
}
