import type { RenderEngine } from "white-web-sdk";

import clsx from "clsx";
import React, { useCallback, useEffect, useState, useSyncExternalStore } from "react";

import { joinRoom } from "../behaviors/api";
import { FastboardModule, fastboardPromise } from "../behaviors/fastboard";
import { get_render_engine, update_query } from "../behaviors/query";
import { Region } from "../behaviors/region";
import { useSafePromise } from "../helpers/use-safe-promise";
import { Loading } from "./Loading";
import { useTheme } from "../helpers/use-theme";
import { config as config_, config$ } from "../behaviors/config";

export interface WhiteboardProps {
  uid: string;
  nickName: string;
  uuid: string;
  region: Region;
  // if exist, use it (directly created by user)
  // if not exist, ask server for one (via share url)
  roomToken: string | null;
}

function fetchRoomToken(uuid: string, region: Region): Promise<string> {
  return joinRoom(uuid, region).then((r) => r.roomToken);
}

export function Whiteboard({ uid, nickName, uuid, roomToken: roomToken_, region }: WhiteboardProps) {
  const sp = useSafePromise();
  const [fastboard, setFastboard] = useState<FastboardModule | null>(null);
  const [roomToken, setRoomToken] = useState(roomToken_);
  const [loading, setLoading] = useState(true);

  const onReady = useCallback(() => setLoading(false), []);

  useEffect(() => {
    if (!fastboard) {
      sp(fastboardPromise).then(setFastboard);
    }
  }, []);

  useEffect(() => {
    if (roomToken) {
      // hide room token from address bar
      update_query({ token: undefined, tempName: undefined });
    } else {
      // fetch room token from server
      sp(fetchRoomToken(uuid, region)).then(setRoomToken);
    }
  }, [roomToken]);

  return (
    <>
      {(!fastboard || !roomToken || loading) && (
        <Loading>
          <div className="loading-panel">
            <div className="loading-module">Loading fastboard&hellip; {fastboard ? "done" : ""}</div>
            <div className="loading-token">Fetching room token&hellip; {roomToken ? "done" : ""}</div>
            <div className="loading-room">Connecting to room&hellip;</div>
          </div>
        </Loading>
      )}
      {fastboard && roomToken && (
        <div className="whiteboard">
          <FastboardWrapper
            uid={uid}
            nickName={nickName}
            fastboard={fastboard}
            uuid={uuid}
            roomToken={roomToken}
            region={region}
            onReady={onReady}
          />
        </div>
      )}
    </>
  );
}

type WrapperProps = WhiteboardProps & {
  roomToken: string;
  fastboard: FastboardModule;
  onReady: () => void;
};

function FastboardWrapper({ fastboard, onReady, ...props }: WrapperProps) {
  const [theme] = useTheme();
  const config = useSyncExternalStore(config$.subscribe, () => config_);
  const { Fastboard, useFastboard } = fastboard;

  const app = useFastboard(() => ({
    sdkConfig: {
      appIdentifier: import.meta.env.VITE_APPIDENTIFIER,
      region: props.region,
      renderEngine: (get_render_engine() === "svg" ? "svg" : "canvas") as RenderEngine,
    },
    joinRoom: {
      uid: props.uid,
      uuid: props.uuid,
      roomToken: props.roomToken,
      userPayload: {
        nickName: props.nickName,
      },
    },
  }));

  useEffect(() => {
    if (app) {
      const fastboard = app;
      const { sdk, room, manager, syncedStore } = fastboard;
      Object.assign(window, { fastboard, sdk, room, manager, syncedStore });

      const stop_listen_phase = app.phase.subscribe((phase) => {
        if (phase === "connected") {
          onReady();
        }
      });

      return () => {
        stop_listen_phase();
        Object.assign(window, {
          fastboard: null,
          sdk: null,
          room: null,
          manager: null,
          syncedStore: null,
        });
      };
    }
  }, [app]);

  const [tips, showTips] = useState(false);

  const onDragOver = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
      showTips(true);
    },
    [app]
  );

  const onDragLeave = useCallback(() => {
    showTips(false);
  }, []);

  const onDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files[0];
      if (app && file && file.name.endsWith(".scene")) {
        console.log("import scene", file.name);
        app.room
          .importScene("/", file)
          .then(() => app.syncedStore.nextFrame())
          .then(async (_scene) => {
            let nextSceneName = file.name.slice("whiteboard-".length, -".scene".length);

            // FIXME: wait white-web-sdk next version (> 2.16.47) to fix it
            // nextSceneName = _scene.name;
            const buffer = await file.arrayBuffer();
            const view = new DataView(buffer);
            if (view.getUint32(0, true) === 0x6f6b6b61) {
              const bare = buffer.slice(8 + view.getUint32(4, true));
              const blob = new Blob([bare]);
              const scene = await (app.room as any).getSceneFromBlob(blob);
              if (scene && scene.name) {
                nextSceneName = scene.name;
              }
            }

            console.log("import scene success ->", nextSceneName);
            const index = app.manager.sceneState.scenes.findIndex((e) => e.name === nextSceneName);
            if (index != null && index >= 0) {
              // change current scene without refreshing -- which is impossible
              // so create a dummy scene and switch it back
              if (app.sceneIndex.value === index) {
                await app.addPage({ after: true });
                await app.nextPage();
                await app.removePage();
              }
              app.sceneIndex.set(index);
            }
          })
          .catch((error) => {
            console.error("import scene failed");
            console.error(error);
            alert("" + error);
          })
          .finally(() => {
            showTips(false);
          });
      }
    },
    [app]
  );

  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative" }}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Fastboard
        app={app}
        language={props.region.startsWith("cn") ? "zh-CN" : "en"}
        theme={theme}
        config={config}
      />
      <div className={clsx("tips", tips && "show")}>Importing&hellip;</div>
    </div>
  );
}
