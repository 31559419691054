import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import logoPNG from "../assets/logo.png";

import { createRoom } from "../behaviors/api";
import { get_name, random_name, update_query } from "../behaviors/query";
import { getDefaultRegion, Region, regions } from "../behaviors/region";
import { useSafePromise } from "../helpers/use-safe-promise";

export function Home() {
  const sp = useSafePromise();
  const navigate = useNavigate();
  const [flip, setFlip] = useState(0);
  const [nickName, setNickName] = useState(get_name);
  const [region, setRegion] = useState(getDefaultRegion);
  const [uuid, setUUID] = useState("");
  const [token, setToken] = useState("");
  const [beginAt, setBeginAt] = useState("");
  const [duration, setDuration] = useState("");

  useEffect(() => {
    update_query({ tempName: nickName || undefined });
  }, [nickName]);

  useEffect(() => {
    update_query({ region });
  }, [region]);

  async function go() {
    let name = nickName || random_name();
    update_query({ tempName: name });
    const room = await sp(createRoom(region));
    update_query({ token: room.roomToken, region: room.region });
    navigate(`/room/${room.roomUUID}${location.search}`);
  }

  async function join() {
    let name = nickName || random_name();
    update_query({ tempName: name, token, region });
    navigate(`/room/${uuid}${location.search}`);
  }

  async function replay() {
    update_query({
      tempName: undefined,
      token,
      region,
      beginTimestamp: beginAt,
      duration,
    });
    navigate(`/replay/${uuid}${location.search}`);
  }

  const comboRef = useRef(0);
  function combo(key: string) {
    if (key === "Control") {
      comboRef.current++;
      if (comboRef.current === 2) {
        comboRef.current = 0;
        setNickName(random_name());
      }
    } else if (key === "Enter" && nickName) {
      go();
    } else {
      comboRef.current = 0;
    }
  }

  return (
    <div className="home-wrapper">
      <div className={`home flip-${flip}`}>
        {create_room(flip === 0)}
        {join_room()}
        {replay_room(flip === 2)}
        <button
          className="btn-join"
          title={flip ? "New" : "Join"}
          onClick={() => setFlip((flip) => (flip ? 0 : 1))}
        >
          JOIN
        </button>
      </div>
    </div>
  );

  function join_room() {
    return (
      <div className="back">
        <div className="logo">
          <img src={logoPNG} alt="logo" />
        </div>
        <h1 className="title">Join Room</h1>
        <div className="input-uuid">
          <input
            value={uuid}
            placeholder="Room UUID"
            onChange={(ev) => setUUID(ev.target.value)}
          />
        </div>
        <div className="input-token">
          <input
            value={token}
            placeholder="Room Token"
            onChange={(ev) => setToken(ev.target.value)}
          />
        </div>
        <div className="input-region">
          <select
            value={region}
            onChange={(ev) => setRegion(ev.target.value as Region)}
          >
            {regions.map((item) => (
              <option key={item.region} value={item.region}>
                {item.emoji} {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="btn-go">
          <button
            className="btn"
            title="Create Room"
            disabled={!uuid}
            onClick={join}
          >
            GO
          </button>
        </div>
        <button
          className="btn-replay"
          title="Replay"
          onClick={() => setFlip((flip) => (flip === 1 ? 2 : 1))}
        >
          REPLAY
        </button>
      </div>
    );
  }

  function create_room(show: boolean) {
    return (
      <div className={clsx("front", { show })}>
        <div className="logo">
          <img src={logoPNG} alt="logo" />
        </div>
        <h1 className="title">Fastboard Demo</h1>
        <div className="input-nick-name">
          <input
            autoFocus
            value={nickName}
            placeholder="What's your name?"
            onChange={(ev) => setNickName(ev.target.value)}
            onKeyUp={(ev) => combo(ev.key)}
          />
        </div>
        <div className="input-region">
          <select
            value={region}
            onChange={(ev) => setRegion(ev.target.value as Region)}
          >
            {regions.map((item) => (
              <option key={item.region} value={item.region}>
                {item.emoji} {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="btn-go">
          <button
            className="btn"
            title="Create Room"
            disabled={!nickName}
            onClick={go}
          >
            GO
          </button>
        </div>
        <div className="document">
          <a
            href="https://docs.agora.io/en/interactive-whiteboard/get-started/get-started-uikit?platform=web"
            target="_blank"
          >
            Documentation
          </a>
        </div>
      </div>
    );
  }

  function replay_room(show: boolean) {
    return (
      <div className={clsx("front", { show })}>
        <div className="logo">
          <img src={logoPNG} alt="logo" />
        </div>
        <h1 className="title">Replay Room</h1>
        <div className="input-uuid">
          <input
            value={uuid}
            placeholder="Room UUID"
            onChange={(ev) => setUUID(ev.target.value)}
          />
        </div>
        <div className="input-token">
          <input
            value={token}
            placeholder="Room Token"
            onChange={(ev) => setToken(ev.target.value)}
          />
        </div>
        <div className="input-begin-at">
          <input
            value={beginAt}
            placeholder="Begin (in miliseconds)"
            onChange={(ev) => setBeginAt(ev.target.value)}
          />
        </div>
        <div className="input-duration">
          <input
            value={duration}
            placeholder="Duration (in miliseconds)"
            onChange={(ev) => setDuration(ev.target.value)}
          />
        </div>
        <div className="input-region">
          <select
            value={region}
            onChange={(ev) => setRegion(ev.target.value as Region)}
          >
            {regions.map((item) => (
              <option key={item.region} value={item.region}>
                {item.emoji} {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="btn-go">
          <button
            className="btn"
            title="Replay Room"
            disabled={!uuid || !token}
            onClick={replay}
          >
            REPLAY
          </button>
        </div>
        <button
          className="btn-join btn-join-2"
          title="Join"
          onClick={() => setFlip(1)}
        >
          JOIN
        </button>
      </div>
    );
  }
}
