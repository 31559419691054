export let theme: "light" | "dark" = "light";

const callbacks: Array<(t: typeof theme) => void> = [];

function setTheme(newTheme: "light" | "dark") {
  theme = newTheme;
  callbacks.forEach((cb) => cb(theme));
}

export function toggleTheme() {
  setTheme(theme === "light" ? "dark" : "light");
}

if (typeof matchMedia !== "undefined") {
  const query = matchMedia("(prefers-color-scheme: dark)");
  setTheme(query.matches ? "dark" : "light");
  query.addEventListener("change", (e) => {
    setTheme(e.matches ? "dark" : "light");
  });
}

export const theme$ = {
  subscribe(cb: (t: typeof theme) => void) {
    callbacks.push(cb);
    cb(theme);
    return () => {
      const index = callbacks.indexOf(cb);
      if (index !== -1) {
        callbacks.splice(index, 1);
      }
    };
  },
};

theme$.subscribe((theme) => {
  if (typeof document !== "undefined") {
    document.documentElement.dataset.theme = theme;
  }
});
