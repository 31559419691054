import { useCallback, useEffect, useRef } from "react";

/**
 * Leave promise unresolved when the component is unmounted.
 * @example
 * ```js
 * const sp = useSafePromise()
 * setLoading(true)
 * try {
 *   const result = await sp(fetchData())
 *   setData(result)
 * } catch (e) {
 *   setError(true)
 * }
 * setLoading(false)
 * ```
 */
export function useSafePromise() {
  const isUnmountedRef = useRef(false);

  useEffect(
    () => () => {
      isUnmountedRef.current = true;
    },
    []
  );

  function safePromise<T, E = unknown>(
    promise: PromiseLike<T>,
    onUnmountedError?: (error: E) => void
  ): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await promise;
        if (!isUnmountedRef.current) {
          resolve(result);
        }
        // unresolved promises will be garbage collected
      } catch (error) {
        if (!isUnmountedRef.current) {
          reject(error);
        } else if (onUnmountedError) {
          onUnmountedError(error as E);
        } else {
          if (import.meta.env.DEV) {
            console.error(
              "An error occurs from a promise after a component is unmounted",
              error
            );
          }
        }
      }
    });
  }

  return useCallback(safePromise, []);
}
