import React from "react";
import { useTheme } from "../helpers/use-theme";

export function ThemeSwitcher() {
  const [theme, toggle] = useTheme();
  return (
    <button className="theme-switcher" title={"theme: " + theme} onClick={toggle}>
      <i className={theme === "dark" ? "i-mdi-moon-waxing-crescent" : "i-mdi-white-balance-sunny"}></i>
    </button>
  );
}
