// This is a fake user system where uid is generated randomly.
// We want it to be the same even when refreshed the page.
// So we store it in the querystring (instead of local storage,
// in case of you want to test the user system on the same browser).

// For anyone who want to test multi-player apps, you can set the uid
// and nickName in querystring to simulate different users.

let query: URLSearchParams | undefined;

export function make_uid() {
  return Math.random().toString(36).slice(2);
}

export function get_uid() {
  query ||= new URLSearchParams(location.search);
  let uid = query.get("uid");
  if (!uid) {
    uid = make_uid();
    update_query({ uid });
  }
  uid && console.debug("uid =", uid);
  return uid;
}

export function random_name() {
  return NAMES[(Math.random() * NAMES.length) | 0];
}

export function get_name() {
  query ||= new URLSearchParams(location.search);
  let name = query.get("tempName") || query.get("nickName") || "";
  name && console.debug("nickName =", name);
  return name;
}

export function get_render_engine() {
  query ||= new URLSearchParams(location.search);
  let value = query.get("render") || "";
  value && console.debug("renderEngine =", value);
  return value;
}

export function update_query(set: Record<string, string | undefined>) {
  query ||= new URLSearchParams(location.search);
  for (const key of Object.keys(set)) {
    if (set[key] === undefined) {
      query.delete(key);
    } else {
      query.set(key, set[key]!);
    }
  }
  history.replaceState(null, "", "?" + query.toString());
}

// cSpell:disable
const NAMES = [
  "Alice",
  "Bob",
  "Charlie",
  "David",
  "Eve",
  "Frank",
  "George",
  "Harry",
  "Ida",
  "Jill",
  "Kathy",
  "Larry",
  "Mary",
  "Nancy",
  "Olivia",
  "Peter",
  "Quincy",
  "Robert",
  "Sally",
  "Tom",
  "Ursula",
  "Victor",
  "Wendy",
  "Xavier",
  "Yvonne",
  "Zachary",
];
