import { useLayoutEffect, useState } from "react";
import { theme as initialValue, theme$, toggleTheme } from "../behaviors/theme";

export function useTheme() {
  const [theme, setTheme] = useState(initialValue);

  useLayoutEffect(() => theme$.subscribe(setTheme), []);

  return [theme, toggleTheme] as const;
}
