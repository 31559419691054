import React from "react";
import { Route, Routes } from "react-router-dom";

import { Home } from "./Home";
import { NotFound } from "./NotFound";
import { Room } from "./Room";
import { Replay } from "./Replay";
import { ThemeSwitcher } from "./ThemeSwitcher";
import "uno.css";

export function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room/:uuid" element={<Room />} />
        <Route path="/replay/:uuid" element={<Replay />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ThemeSwitcher />
    </>
  );
}
