import React from "react";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <div className="404">
      <h1>Not found</h1>
      <p>
        <Link to="/">Back to the home page?</Link>
      </p>
    </div>
  );
}
